import { AiOutlineMinus } from "react-icons/ai";

export const getFormattedCell = (data) => {
  return data ? data : <AiOutlineMinus />;
};
export const getLabelFromCamelCase = (str) => {
  const words = str.split(/(?=[A-Z][a-z])/);

  const labels = {
    numberOfSlidePerPresentation: "Number Of Slides",
  };

  const label =
    labels[str] ||
    words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

  return label;
};
// export const validPhone = /[^0-9-+]/g;
export const validPhone = /[^0-9+()-]|([+-])\1+/g
export const onlyPhone = (str) => {
  if (str?.length > 0) {
    str = str?.replace(validPhone, '')
  }
  return str;
}


export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};